import React, { Component } from 'react';
import { withRouter } from 'react-router'
import axios from 'axios';
import { Slider } from 'antd';
import {
  AppBar,
  Toolbar,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';
import './../App.css';
import Footer from './footer';

// const resJson = {
//   "status": 2,
//   "statusList": {
//     0: '未対応',
//     1: '対応中',
//     2: '依頼済',
//     3: '対応完了',
//   },
//   "max": 3,
// }

const requestUrl = 'https://k7134csil9.execute-api.ap-northeast-1.amazonaws.com/prod';

const styles = {
  root: {
    margin: '10px',
    flexGrow: 1,
  },
  header: {
    background: '#1e88e5',
    textAlign: 'left',
  },
  title: {
    background: '#1e88e5',
    color: '#ffffff',
  },
  content: {
    margin: '10px',
  },
  circular: {
    textAlign: 'center',
  },
}

type Props = {
  classes: any,
};

class StatusView extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      statusList: {},
      status: 0,
      max: 0,
      isLoading: false,
    }
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    })
    try {
      const urlParam = this.makeUrlParam();
      if(urlParam) {
        const url = requestUrl + urlParam;
        await axios.get(url)
        .then(response => {
            const resJson = response.data;
            const isMainte = Boolean(resJson.mainte);
            if(isMainte){
              this.props.history.push("/mainte");
            } else {
              const isExpiration  = Boolean(resJson.diff);
              if (isExpiration) {
                this.props.history.push("/expiration");
              } else {
                this.setState({
                  statusList: resJson.statusList,
                  status: resJson.status,
                  max: resJson.max,
                })
                this.setState({
                  isLoading: false,
                })
              }
            }
        })
      } else {
        this.props.history.push("/error");
      }
    } catch (error) {
      console.log('error:', error);
      this.props.history.push("/error");
    }
  }

  makeUrlParam = () => {
    let urlParam = null;
    const dencodeUrl = decodeURIComponent(window.location.search);
    const params = dencodeUrl.substr(1).split('=');
    const aspId = params[0];
    const datetime = params[1].split('/').join('');
    const receptHistId = params[2];
    if(aspId && datetime && receptHistId) {
      urlParam = "?aspId="  + aspId + "&receptHistId=" + receptHistId + "-" + datetime;
    }
    return urlParam;
  }

  render() {
    const { classes } = this.props;
    const { statusList, status, max, isLoading } = this.state;
    return (
      <div>
        <AppBar position="static" color="default">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              対応状況
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          className={classes.root}
        >
        {isLoading ? (
            <Grid item xs={12}
              className={classes.circular}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  className={classes.header}
                  classes={{
                    title: classes.title,
                  }}
                  title={'現在のステータス'}
                >
                </CardHeader>
                <CardContent
                  className={classes.content}
                >
                  <Slider
                    marks={statusList}
                    value={status}
                    max={max}
                  />
                </CardContent>
              </Card>
            </Grid>
          )
        }
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(StatusView));
